<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <img height="70" :src="require('@/assets/logo_full.png')" />
        <h2>Bienvenido al Nuevo Sistema de <br />Sinergia Médica</h2>
        <h4 class="pt-2">
          Usuario: {{ login.email }}<br />({{ login.role_name }})
        </h4>
      </v-col>
    </v-row>
    <v-dialog v-model="passwd_dlg" persistent max-width="600">
      <v-card tile :disabled="passwd_dlg_ldg" :loading="passwd_dlg_ldg">
        <v-toolbar dark>
          <v-toolbar-title> SESIÓN </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items> </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="passwd_dlg">
          <v-container>
            <v-form v-on:submit.prevent ref="passwd_form" lazy-validation>
              <v-row>
                <v-col cols="12" class="text-center pt-5">
                  <b>Contraseña con mas de 90 días, favor de actualizarla</b>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Contraseña*"
                    v-model="passwd"
                    :rules="rules.password"
                    :type="passwd_show ? 'text' : 'password'"
                    :append-icon="passwd_show ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="passwd_show = !passwd_show"
                    autocomplete="new-password"
                  />
                </v-col>
                <v-col cols="12">
                  <v-btn block small color="warning" @click="passwdSubmit">
                    Actualizar
                    <v-icon right> mdi-send </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  rules,
  msgConfirm,
  msgAlert,
} from "./../../control";

export default {
  data() {
    return {
      login: this.$store.getters.getLogin,
      passwd: "",
      passwd_dlg: false,
      passwd_dlg_ldg: false,
      passwd_show: false,
      rules: rules(),
    };
  },
  methods: {
    passwdDlg() {
      this.passwd_show = false;
      this.passwd = "";
      this.passwd_dlg_ldg = false;
      this.passwd_dlg = true;
    },
    passwdSubmit() {
      if (this.$refs.passwd_form.validate()) {
        this.$swal
          .fire(msgConfirm("¿Confirma actualizar contraseña?"))
          .then((res) => {
            if (res.isConfirmed) {
              this.passwd_dlg_ldg = true;

              Axios.post(
                URL_API + "/general/users/password/update",
                {
                  password: this.passwd,
                },
                headersToken(this.login.token)
              ).then((res) => {
                this.$swal.fire(
                  msgAlert(
                    res.data.success ? "success" : "error",
                    res.data.message
                  )
                );

                if (res.data.success) {
                  this.$store.dispatch("passwdUpdateAction");
                  this.passwd_dlg = false;
                } else {
                  console.log(res.data.message);
                }

                this.passwd_dlg_ldg = false;
              });
            }
          });
      }
    },
  },
  mounted() {
    if (this.login.passwd_update) {
      this.passwdDlg();
    }
  },
};
</script>
